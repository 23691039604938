import {ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import {AuthService} from 'app/core/auth/auth.service';

import {Interceptor} from './utils/intercept.service';
import {FormUtilsService} from './utils/form-utils.service';
import {TableUtilsService} from './utils/table-utils.service';
import {NotificationUtilsService} from './utils/notification-utils.service';
import {StorageUtilsService} from './utils/storage.utils.service';
import {GlobalErrorHandler} from './loggers/global-error.handler';
import {ZLoggerService} from './loggers/zlogger.service';

@NgModule({
    imports: [
        HttpClientModule,
        MatSnackBarModule
    ],
    providers: [
        AuthService,
        FormUtilsService,
        TableUtilsService,
        NotificationUtilsService,
        StorageUtilsService,
        ZLoggerService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true
        },
    ]
})
export class CoreModule {
    /**
     * Constructor
     *
     * @param {DomSanitizer} _domSanitizer
     * @param {MatIconRegistry} _matIconRegistry
     * @param parentModule
     */
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry,
        @Optional() @SkipSelf() parentModule?: CoreModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }

        // Register icon sets

        this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'));

    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatMenuModule} from '@angular/material/menu';

import {UploadImageModule} from './components/upload-image/upload-image.module';
import {ViewImageModule} from './components/view-image/view-image.module';
import {TextEditorModule} from './components/text-editor/text-editor.module';
import {BackButtonModule} from './components/back-button/back-button.module';

import {CheckScrollDirective} from './directive/check-scroll.directive';

import {FabMenuModule} from './components/mat-fab-menu/fab-menu.module';
import {CustomSelectModule} from './components/custom-select/custom-select.module';
import {ActionNotificationModule} from './components/action-natification/action-notification.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UploadImageModule,
        ViewImageModule,
        TextEditorModule,
        MatButtonToggleModule,
        MatMenuModule,
        BackButtonModule,
        FabMenuModule,
        CustomSelectModule
    ],
    declarations: [
        CheckScrollDirective
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CheckScrollDirective,
        UploadImageModule,
        ViewImageModule,
        TextEditorModule,
        BackButtonModule,
        FabMenuModule,
        CustomSelectModule,
        ActionNotificationModule
    ]
})
export class SharedModule {
}

/** Angular */
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {MatPaginator} from '@angular/material/paginator';
import {FormBuilder, FormGroup} from '@angular/forms';

import {StorageUtilsService} from './storage.utils.service';
import {FilterConfigTypes} from '../../modules/main/table/filter-config.types';

import moment from 'moment';

import numeral from 'numeral';

export const ColorStatusType = {
    yes: '#1aaa55',
    no: '#db3b21',
    blocked: '#db3b21',
    paid: '#1aaa55',
    processing: '#F0AD4E',
    unpaid: '#d3d3d3',
    cancelled: '#ff5fb7',
    failed: '#db3b21',
    forbidden: '#256d74',
    refunded: '#f6c820',
    declined: '#203af6',
    active: '#1aaa55',
    disabled: '#db3b21',
    new: '#1aaa55',
    answered: '#F0AD4E',
    seen: '#d3d3d3',
    dispute: '#9b00dd',
    merged: '#4287f5',
    high: '#ff0000',
    low: '#71cc1d',
    medium: '#e68a12',
    waiting: '#92b9ff',
};

@Injectable()
export class TableUtilsService {
    filter: any;

    allTableParams: any = [];

    constructor(private _router: Router,
                private _formBuilder: FormBuilder,
                private _storageUtilsService: StorageUtilsService) {
    }

    setLocalParams(paginator: any, sort?, filterValue?: any, queryParams?): any {
        const path = this._router.url.split(' ').join('');
        let filter = null;
        if (filterValue) {
            Object.keys(filterValue).map(field => {
                if (filterValue[field] || filterValue[field] === 0) {
                    filter = filterValue;
                    return;
                }
            });
        }
        let tableParams = {
            ...{filterValue: filter},
            ...{path: path},
            ...{queryParams: queryParams}
        };
        if (paginator) {
            tableParams = {
                ...{
                    pageSize: paginator.pageSize,
                    pageIndex: paginator.pageIndex
                },
                ...tableParams
            };
        }
        if (sort) {
            tableParams = {
                ...{
                    active: sort.active,
                    direction: sort.direction
                },
                ...tableParams
            };
        }
        const index = this.allTableParams.findIndex(el => el.path === path);
        if (index !== -1) {
            this.allTableParams[index] = tableParams;
        } else {
            this.allTableParams.push(tableParams);
        }
        this._storageUtilsService.set('allTableParams', JSON.stringify(this.allTableParams));

    }

    getLocalParams(url?: string): any {
        const path = url && url.length ? url : this._router.url;
        let index = this.allTableParams.findIndex(el => el.path === path);
        if (index !== -1) {
            return this.allTableParams[index];
        } else {
            if (this._storageUtilsService.check('allTableParams')) {
                this.allTableParams = JSON.parse(this._storageUtilsService.get('allTableParams'));
                if (this.allTableParams && this.allTableParams.length) {
                    index = this.allTableParams.findIndex(el => el.path === path);
                    if (index !== -1) {
                        return this.allTableParams[index];
                    }
                }
            }
        }
        return null;
    }

    setInComponentLocalParams(data, firstLoadParams: any = null, isNewFilter: boolean = false): any {
        const tableParams = this.getLocalParams();
        if (this.isEmptyObject(tableParams)) {
            return;
        }
        if (firstLoadParams) {
            tableParams.filterValue = {...tableParams.filterValue, ...firstLoadParams};
        }
        if (data.paginator) {
            data.paginator.pageSize = tableParams.pageSize;
            data.paginator.pageIndex = tableParams.pageIndex;
        }
        if (data.sort) {
            if (isNewFilter) {
                const sortData = {
                    active: tableParams.active || '',
                    direction: tableParams.direction || ''
                };
                data.sort.setValue(sortData);
            } else {
                data.sort.active = tableParams.active;
                data.sort.direction = tableParams.direction;
            }
        }
        if (tableParams.filterValue) {
            if (data.checkTypeFieldFilter) {
                data.checkTypeFieldFilter(tableParams.filterValue);
            }
            data.filterForm.setValue(tableParams.filterValue);
            if (!isNewFilter) {
                data.openFilter();
            }
        }
        data.cdr.markForCheck();
        data.cdr.detectChanges();
    }

    isEmptyObject(obj): boolean {
        for (const i in obj) {
            if (obj.hasOwnProperty(i)) {
                return false;
            }
        }
        return true;
    }

    getQueryParams(paginator?: MatPaginator, sort?: any, params?: any, filterValue?: any, dontSaveLocalParams?: any): any {
        this.filter = {...{}, ...params};
        if (sort) {
            // this.filter.sortField = sort.active;
            // this.filter.sortOrder = sort.direction;
            this.clearOrderBy(this.filter);
            this.filter[sort.active + '[orderBy]'] = sort.direction;
        }
        if (paginator) {
            this.filter.per_page = paginator.pageSize;
            let pi = paginator.pageIndex;
            this.filter.page = ++pi;
        }
        if (filterValue) {
            this.checkSearchField(this.filter, filterValue);
        }
        if (dontSaveLocalParams) {
            return this.filter;
        }
        this.setLocalParams(paginator, sort, filterValue, this.filter);
        return this.filter;
    }

    clearOrderBy(data: any): any {
        Object.keys(data).map(key => {
            if (key.includes('orderBy') || key === 'status' || key === 'user_id') {
                delete data[key];
            }
        });
    }

    checkSearchField(_filter: any, filterValue: any): void {
        Object.keys(filterValue).map(field => {
            if (filterValue[field] || filterValue[field] === 0) {
                field === 'created_at' || field === 'next_payment_date' || field === 'scheduled_at'
                || field === 'updated_at' || field === 'closed_at' ?
                    _filter[field] = moment(filterValue[field]).format('YYYY-MM-DD') :
                    _filter[field] = filterValue[field];
            }
            if (Array.isArray(filterValue[field]) && filterValue[field].length) {
                _filter[field] = filterValue[field].join(',');
            }
        });
    }

    formatMoney(val: string | number | null): string {
        if (val === null) {
            return '0';
        }
        return numeral(val).format('0,0.00');
    }

    getFilterForm(filterConfigs: FilterConfigTypes[]): FormGroup {
        const controlsConfigs = {};
        filterConfigs.map(item => {
            if (item.type === 'group') {
                item.items.map(groupItem => {
                    controlsConfigs[groupItem.formField] = groupItem.value || '';
                });
            } else {
                controlsConfigs[item.formField] = item.value || '';
            }
        });
        return this._formBuilder.group(controlsConfigs);
    }

    /**
     * Returns CSS Class by status
     *
     * @param status: number
     */
    getItemCssClassByStatus(status: string | number = 'disabled'): any {
        let color = '#27303f';
        if (typeof status === 'number') {
            status = status ? 'yes' : 'no';
        }
        Object.keys(ColorStatusType).map(field => {
            if (field === status) {
                color = ColorStatusType[field];
            }
        });
        return color;
    }

    spliceItems(items: any[], field: string): void {
        const filterIndex = items.findIndex(item => item.formField === field);
        items.splice(filterIndex, 1);
    }

    getTimeAgo(date: any): string {
        return !!date ? moment(date).fromNow() : '';
    }

    checkTypeSymbol(text: string, type: string): string {
        if (type === 'percent') {
            return text + '%';
        } else {
            return '$' + text;
        }
    }
}
